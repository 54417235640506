import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/admin',
    name: 'container',
    component: (resolve) => require(['@/components/admin/container'], resolve),
    children: [{
            path: 'patrol',
            name: 'patrol',
            component: (resolve) => require(['@/components/admin/patrol/index'], resolve)
        },
        {
            path: 'law',
            name: 'law',
            component: (resolve) => require(['@/components/admin/law/index'], resolve)
        },
        {
            path: 'lawmanagement',
            name: 'lawmanagement',
            component: (resolve) => require(['@/components/admin/lawmanagement/index'], resolve)
        },
        {
            path: 'contrastive-analysis',
            name: 'contrastive-analysis',
            component: (resolve) => require(['@/components/admin/contrastive-analysis'], resolve)
        },
        {
            path: 'map',
            name: 'map',
            component: (resolve) => require(['@/components/admin/map/index'], resolve)
        },
        {
            path: 'ckeditor4',
            name: 'ckeditor4',
            component: (resolve) => require(['@/components/admin/ckeditor4/ckeditor4'], resolve)
        },
        {
            path: "*", //前面都匹配不上了
            redirect: "/admin/map"
        }
    ]
}, {
    path: "/",
    redirect: "/admin/map"
}]

const router = new VueRouter({
    routes
})

export default router