import Vue from 'vue'
import '@/plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '../public/iconfont/iconfont.css'
import '../public/iconfont2/iconfont.css'
// 动画效果
import animated from 'animate.css'

import scroll from 'vue-seamless-scroll'
// 数字滚动
import VueAnimateNumber from 'vue-animate-number'

//引入swiper的样式

import VueAwesomeSwiper from 'vue-awesome-swiper'
import "swiper/dist/css/swiper.min.css"
Vue.use(VueAnimateNumber)

import echarts from 'echarts'
Vue.prototype.$echarts = echarts




Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(animated)
Vue.use(scroll)
Vue.use(VueAwesomeSwiper)

// import axios from 'axios'
// 如果后续需求有要求可清除
// if (window.localStorage.getItem('Auth', token)) {

// }

// console.log(token, "token");
// if (!localStorage.getItem('Auth')) {
//     var token = window.parent.location.href.split("token=")[1];

//     window.localStorage.setItem("Auth", token);
//     // util.req_post("", api.dict_getAllDict, function(_r) {
//     //   if (_r.success) {
//     //     window.localStorage.setItem("tr_tr_dicts", JSON.stringify(_r.result));
//     //   }
//     // });
// } else {
//     // token =
//     // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2Mzg0NTEyMjQsInVzZXJuYW1lIjoiYWRtaW4ifQ.mlDmyDMOhISiebQcIh7kzvgAlbLu4dRimPyO6o0F4tM";
//     // window.localStorage.setItem("Auth", token);
// }

var token = window.parent.location.href.split("token=")[1];

if (!token) {
    window.location.replace("http://user.ytzhhy.cn")
} else {
    window.localStorage.setItem("Auth", token);
}

axios.interceptors.request.use((config) => {

        if (!localStorage.getItem('Auth')) {
            var token = window.parent.location.href.split("token=")[1];
            if (!token) {
                window.location.replace("http://user.ytzhhy.cn")
            } else {
                window.localStorage.setItem("Auth", token);
            }
        }
        // 一般请求拦截器只需要考虑第一个函数
        // 1 如果我们要给所有的请求中添加header
        // config.headers.key = "value"
        // 2 如果我们要给所有的请求添加query参数
        // config.params.key = "value"
        // 3 如果我们要给所有的请求添加body参数
        // config.data.key = "value"
        // console.log(config, '=====>config');
        // 获取token
        const tokenw = localStorage.getItem('Auth')
        if (tokenw) {
            // 具体的authorization应该是什么需要看后台给的问题  需不需要添加Bearer也需要看文档
            config.headers.Auth = tokenw
        }
        config.headers.Auth = tokenw
            // if (config.method === 'post' ) {
            //     config.headers.Auth = tokenw
            // } else if (config.method === 'get') {
            //     config.headers.Auth = tokenw
            // }
        return config
    }, (err) => {
        // window.location.replace("http://user.ytzhhy.cn);
        return Promise.reject(err)
    })
    // Vue.prototype.axios = axios

axios.interceptors.response.use(res => {
    //对响应数据做些事
    if (res) {
        // alert(res.error_msg)
        // return Promise.reject(res)
        return res

    } else if (res.data.code == 408) {
        window.location.replace("http://user.ytzhhy.cn/ocean-ranch");
    }

}, error => {
    if (error.response.code == 408) {
        // 401 说明 token 验证失败
        // 可以直接跳转到登录页面，重新登录获取 token
        // location.href = '/login'
        window.location.replace("http://user.ytzhhy.cn/ocean-ranch");
    }
    // else if (error.response.code === 500) {
    //     // 服务器错误
    //     // do something
    //     // return Promise.reject(error.response.data)
    //     window.location.replace("http://user.ytzhhy.cn/ocean-ranch");
    // }
    // 返回 response 里的错误信息
    return Promise.reject(error.response.data)
})




new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')